import { AppConfig } from 'src/app/app.config';
import { Injectable } from "@angular/core";

@Injectable()
export class SwireConstants {
  protected apiServer = AppConfig.settings.apiServer;
  protected appEnviroment = AppConfig.settings.env;
  protected commonConfigurations = AppConfig.settings.commonConfigurations;
  
  swireNewsUrl: string = 'http://local.swirenews.com/';
  appEnv: String = 'LOCAL';
  apiCallUrl: String = '';
  pdfBaseUrl: String = '';
  googleTagKey: String = '';
  googleGTMKey: String = '';
  AWAccountID: String = '';
  productbaseUrl: String = '';
  WPBaseUrl:any;

  constructor() {
    this.setApiSettings();
  }

  setApiSettings(){
    this.appEnv = this.appEnviroment.name;
    this.apiCallUrl = this.apiServer.baseUrl;
    this.pdfBaseUrl = this.commonConfigurations.pdfBaseUrl;
    this.productbaseUrl = this.commonConfigurations.productbaseUrl;
    this.swireNewsUrl = this.commonConfigurations.swireNewsUrl;
    this.WPBaseUrl = this.commonConfigurations.WPBaseUrl;
    this.googleTagKey = this.commonConfigurations.googleTagKey;
    this.googleGTMKey = this.commonConfigurations.googleGTMKey;
    this.AWAccountID = this.commonConfigurations.AWAccountID;
  }

  validateServerEnv(){
    let szHostName = window.location.hostname; 
    if(szHostName=='localhost'){
      return true;
    } else if(szHostName=='ngplfedev.swiredigital-s3-staging.com') {
      return true;
    } else if(szHostName=='ngpl-stage-frontend-alb-1115231655.ap-southeast-1.elb.amazonaws.com' || szHostName=='ngpl-staging.swireshippingdigital.com') {
      return true;
    } else if(szHostName=='ngpl-prod-frontend-alb-523126343.ap-southeast-1.elb.amazonaws.com' || szHostName=='ngpline.com' || szHostName=='www.ngpline.com') {
      return true;
    } else {
      return false;
    }
  }
}
