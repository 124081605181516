<div class="full-width-sos">
    <div class="error-page">
        <h1>
            <span class="error-delay1">S</span>
            <span class="error-delay2">O</span>
            <span class="error-delay3">S</span></h1>
        <div class="error-content">
        <p>{{'Shared.error.text_line1' | translate}}<br/>{{'Shared.error.text_line2' | translate}}<br/></p>
        <span>{{'Shared.error.text_line3' | translate}}</span>
        <a href="/">{{'Shared.error.text_line4' | translate}}</a>
        </div>
        <div class="wave-error">
         <div class="triple-image">
               <img src="../../../../assets/app/media/img/files/sea.svg" alt="Swire Shipping" />
            </div>
        </div>
        </div>
</div>