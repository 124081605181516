import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule,APP_INITIALIZER,Injector } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from "./_services/front/script-loader.service";
import { FrontRoutingModule } from "./front/front-routing.module";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AuthInterceptor } from "./auth.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorComponent } from 'src/app/front/shared/error/error.component';
import { NotFoundComponent } from 'src/app/front/pages/not-found/not-found.component';
import {MultiLanguageComponent } from 'src/app/front/pages/multi_language/multi_language.component'

import { AuthComponent } from 'src/app/auth/auth.component';
import { DataService } from "src/app/_services/front/data.service";
import { SwireConstants } from "src/app/_services/front/constants";
import { CustomAutoCompleteService } from "src/app/_services/front/custom-auto-complete.service"; 
import { StorageService } from 'src/app/_services/front/storage.service';
import { PageTrackingService } from 'src/app/_services/front/page-tracking.service';
import { ValidateHelper } from 'src/app/helpers/validate';
import { ValidationService } from 'src/app/helpers/validateService';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { WindowRef } from 'src/app/utils/WindowRef';
import { NgProgressModule } from 'ngx-progressbar';
import { TagInputModule } from 'ngx-chips';
import { AppConfig } from 'src/app/app.config';

export function initializeApp(appConfig: AppConfig) {
    return () => appConfig.load();
}
import { TranslateLoader, TranslateService, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCATION_INITIALIZED } from '@angular/common';
// import { HttpLoaderFactory , ApplicationInitializerFactory } from './config/translation.config';


export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({ declarations: [
        AppComponent,
        ErrorComponent,
        NotFoundComponent,
        AuthComponent,
        MultiLanguageComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FrontRoutingModule,
        NgProgressModule,
        TagInputModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })], providers: [
        ScriptLoaderService,
        Title,
        DataService,
        SwireConstants,
        CustomAutoCompleteService,
        StorageService,
        PageTrackingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: ApplicationInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        ValidateHelper,
        ValidationService,
        ReactiveFormsModule,
        FormsModule,
        WindowRef,
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig],
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }


export function ApplicationInitializerFactory(
    translate: TranslateService, injector: Injector) {
    let currUrl = window.location.href;
    return async () => {
      if (currUrl.match(/ch$/)) {
        await localStorage.setItem('defaultLang', 'ch');
        await localStorage.setItem('current_url','/ch');
     }
    //  else{
    //    await localStorage.setItem('defaultLang', 'en');
    //  }
      await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      if(!localStorage.getItem('defaultLang')){
          await localStorage.setItem('defaultLang','en')
      } 
      const deaultLang = localStorage.getItem('defaultLang');
      translate.addLangs(['en', 'ch']);
      translate.setDefaultLang(deaultLang);
      try {
        await translate.use(deaultLang).toPromise();
      } catch (err) {
        console.log(err);
      }
      console.log(`Successfully initialized ${deaultLang} language.`);
    };
  }