import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from 'src/app/app-config.model';
import { ConfigNgplDev } from 'src/app/config/config.ngpl-dev';
import { ConfigNgplStage } from 'src/app/config/config.ngpl-stage';
import { ConfigNgplProd } from 'src/app/config/config.ngpl-prod';
import { ConfigNgplLocal } from 'src/app/config/config.ngpl-local';

@Injectable()
export class AppConfig {
    static settings: IAppConfig;
    constructor(private http: HttpClient) {}
    load() {
        return new Promise<void>((resolve, reject) => {
            let szHostName = window.location.hostname; 
            if(szHostName=='localhost'){
                AppConfig.settings = <IAppConfig>ConfigNgplLocal;
            } else if(szHostName=='ngplfedev.swiredigital-s3-staging.com') {
                AppConfig.settings = <IAppConfig>ConfigNgplDev;
            } else if(szHostName=='ngpl-stage-frontend-alb-1115231655.ap-southeast-1.elb.amazonaws.com' || szHostName=='ngpl-staging.swireshippingdigital.com') {
                AppConfig.settings = <IAppConfig>ConfigNgplStage;
            } else if(szHostName=='ngpl-prod-frontend-alb-1115231655.ap-southeast-1.elb.amazonaws.com' || szHostName=='ngpl-prod-frontend-alb-523126343.ap-southeast-1.elb.amazonaws.com' || szHostName=='ngpline.com' || szHostName=='www.ngpline.com') {
                AppConfig.settings = <IAppConfig>ConfigNgplProd;
            } else { 
                AppConfig.settings = <IAppConfig>ConfigNgplLocal;
            }
            resolve();
        });
    }
}