export const ConfigNgplLocal = {
    "env": {
        "name": "NGPL-LOCAL",
        "layout":"NGPL"
    },
    "staticVariables": {
        "headerLogo": "../../../../assets/app/media/ngpl-img/img/white-header-logo.png",
        "headerTitle": "NGPL"
    },
    "apiServer": {
        "baseUrl": "http://localhost:9070/"
    },
    "commonConfigurations": {
        "pdfBaseUrl": "http://dcasvmrn70pnz.cloudfront.net/",
        "productbaseUrl": "https://chinanav.ent.box.com/file/",
        "swireNewsUrl": "http://18.141.43.218/",
        "googleTagKey": "UA-177079883-3",
        "googleGTMKey": "GTM-KM88XZ8",
        "AWAccountID": "AW-727372615",
        "WPBaseUrl": "https://ngpl-info-fedev.swiredigital-s3-staging.com"
    }
}