import { Component, OnInit, ViewEncapsulation, OnChanges, HostListener } from '@angular/core';
import { CustomAutoCompleteService } from 'src/app/_services/front/custom-auto-complete.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "app-error",
    templateUrl: "./error.component.html",
    styleUrls: ['./error.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ErrorComponent implements OnInit {

    
    
  constructor(private routeParams: ActivatedRoute,
        private _autoCompleteService:CustomAutoCompleteService,
        private _router: Router,
        public translate: TranslateService
    ) {
      
    }
    
    ngOnInit() {
        // this.checkBackendServiceWorking();
    }

    switchLang(lang: string) {
      this.translate.use(lang);
    }


    checkBackendServiceWorking(){
        this._autoCompleteService.getResults().subscribe(
          (result) => {
            this._router.navigate(['/']);
          }
        );
    }

}