import { Component , OnInit} from '@angular/core';
@Component({
    selector: 'app-multi_language',
    templateUrl: './multi_language.component.html',
    styleUrls: ['./multi_language.component.scss'],
})
export class MultiLanguageComponent implements OnInit {
        constructor(){}
        ngOnInit(){}
}


