import { Injectable } from '@angular/core';
import { DataService } from 'src/app/_services/front/data.service';
import { StorageService } from 'src/app/_services/front/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CustomAutoCompleteService {

  dataAry:any;

  constructor(private _dataService:DataService,
              private _storageService: StorageService) 
  { }

  getResults() {
    return this._dataService.sendGetRequest('tradeSearch');
  }
  
  getAndStoreServices() {

    let serveLocationResultsTime:any = this._storageService.getDataFromStorage('dataValidUptoTime');
    if(serveLocationResultsTime != '' && serveLocationResultsTime != undefined){
      let currentTime:any = (new Date().getTime());
      if(currentTime <= serveLocationResultsTime){
        let servicesData:any = this._storageService.getDataFromStorage('serveLocationResults');
        if(servicesData != '' && servicesData != undefined){
          
        }else{
          this.getTradeSearchResult();
        }
      }else{
        this.getTradeSearchResult();
      }
    }else{
      this.getTradeSearchResult();
    }
  }
  
  getTradeSearchResult(){
    console.info("getTradeSearchResult called...");
    this._dataService.sendGetRequest('tradeSearch').subscribe(
      (result) => {
        console.info("In results getTradeSearchResult" );
        let dataValidUptoTime = (new Date().setMinutes(120));
        this._storageService.putDataInStorage('serveLocationResults', JSON.stringify(result));
        this._storageService.putDataInStorage('dataValidUptoTime', dataValidUptoTime);
      },
      error => {
        console.info("error ", error);
      }
    );
  }
}
