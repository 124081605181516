import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', "loadChildren": () => import('src/app/front/pages/pages.module').then(m => m.PagesModule) },
    {
        "path": "**",
        "redirectTo": "",
        "pathMatch": "full"
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FrontRoutingModule {
}