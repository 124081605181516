import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { StorageService } from 'src/app/_services/front/storage.service';
import { SwireConstants } from 'src/app/_services/front/constants';
import { CustomHelper } from 'src/app/helpers/custom';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { tap } from 'rxjs/operators';
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    productionEnvironment = environment.production;
    tokenCallEnable: boolean = false;
    staticPagesUrls: any = [
        'vision-values',
        'ourGlobalPresence',
        'cargo-types',
        'shipping-schedules',
        'container-specification',
        'container-detention',
        'termsAndConditions',
        'offices',
        'cookies-policy',
        'dataProtection',
        'termsOfUse'
    ];
    currentUrl: string = '/';
    constructor(
        private router: Router,
        private _storageService: StorageService,
        private _swireConstant: SwireConstants,
        private _location: Location
    ) {
        this.currentUrl = this._location.path();
    }

    intercept(req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
        let _reqToken: string = this._storageService.getDataFromStorage('access_token');
        let _reqPayload: string = this._storageService.getDataFromStorage('access_token_payload');
        let customerKey: string = this._storageService.getDataFromStorage('customerKey');
        let accessTokenExpiry: string = this._storageService.getDataFromStorage('access_token_expiry');

        if (customerKey == '' || customerKey == null || customerKey == undefined) {
            this._storageService.removeDataFromStorage('customerKey');
            this._storageService.putDataInStorage('customerKey', CustomHelper.getRandomString());
        }
        let szToken: any = '';
        let szPayload: any = '';
        if (_reqToken != '' && _reqToken != null && _reqToken != undefined) {
            szToken = _reqToken;
        }
        if (_reqPayload != '' && _reqPayload != null && _reqPayload != undefined) {
            szPayload = _reqPayload;
        }
        let bValidToken = false;
        let currentTime: any = (new Date().getTime());
        if (accessTokenExpiry && szToken && szPayload && (currentTime <= accessTokenExpiry)) {
            bValidToken = true;
        }
        if (
            this.currentUrl == '/vision-values'
            || this.currentUrl == '/ourGlobalPresence'
            || this.currentUrl == '/cargo-types'
            || this.currentUrl == '/shipping-schedules'
            || this.currentUrl == '/container-specification'
            || this.currentUrl == '/container-detention'
            || this.currentUrl == '/termsAndConditions'
            || this.currentUrl == '/offices'
            || this.currentUrl == '/cookies-policy'
            || this.currentUrl == '/dataProtection'
            || this.currentUrl == '/termsOfUse'
        ) {
            return next.handle(req);
        }
        else if (bValidToken) {
            this._storageService.removeDataFromStorage('current_url');
            let authReq = req;
            let arHeaders = req.headers;
            arHeaders.set("x-access-token", szToken);
            arHeaders.set("x-access-payload", szPayload);
            authReq = req.clone({ headers: req.headers.set("x-access-token", szToken) });
            return next.handle(authReq);
        } else {
            if (req.url == this._swireConstant.apiCallUrl + 'auth/getAuthtoken') {
                if (this.currentUrl != '/auth') {
                    this._storageService.putDataInStorage('current_url', this.currentUrl);
                }
                this.router.navigate(['/auth']);
                return next.handle(req);
            } else {
                let lang = this._storageService.getDataFromStorage('defaultLang');
                if(lang == '' || lang == undefined || lang == null){
                    lang = 'en';
                }
                if (req.url == `/assets/i18n/${lang}.json`) {
                    return next.handle(req).pipe(
                        tap(
                            event => {
                                if (event instanceof HttpResponse) {
                                    this.getAuthToken(req, next);
                                }
                            }, 
                            error => {}
                        )
                    )
                    // return next.handle(req);
                } else {
                    if (this.currentUrl != '/auth') {
                        this._storageService.putDataInStorage('current_url', this.currentUrl);
                    }
                    this.getAuthToken(req, next);

                }
            }
        }
    }

    getAuthToken(req, next) {
        let szSecretKey = CustomHelper.getRandomString();
        let modifyReq: any = req;
        modifyReq.url = this._swireConstant.apiCallUrl + 'auth/getAuthtoken';
        modifyReq.urlWithParams = this._swireConstant.apiCallUrl + 'auth/getAuthtoken';
        modifyReq.method = "POST";
        modifyReq.responseType = "json";
        modifyReq.body = {
            "payload": szSecretKey
        };
        this._storageService.putDataInStorage('access_token_payload', szSecretKey);
        this.router.navigate(['/auth']);
        return next.handle(modifyReq);
    }
}

