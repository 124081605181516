import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/_services/front/google-analytics.service';
import { WindowRef } from 'src/app/utils/WindowRef';
import { SwireConstants } from 'src/app/_services/front/constants';


@Injectable()
export class PageTrackingService {
    environment:any = '';
   constructor( 
       private _GoogleAnalyticsService: GoogleAnalyticsService,
       private _windowRef: WindowRef,
       private _swireConstants:SwireConstants
       ) 
    {
        this.environment = this._swireConstants.appEnv;
    }
    /*
    * middleware method for google analytics @Ajay
    */
   public sendPageTracking(arData: any) {  
        let arPageTracing = {
            eventCategory: arData.szCategory || '',
            eventLabel: arData.szLabel || '',
            eventAction: arData.szAction || '',
            eventValue: arData.szEventValue || 0,
            origin: arData.szOrigin || '',
            destination: arData.szDestination || '',
            shareEmailAddress: arData.szEmail || '',
            shareMessage: arData.szEmailBody || '',
            cargoType: arData.szCargoType || '',
            commodityType: arData.szCommodityType || '',
            containerType: arData.szContainerType || '',
            dangerousGoods: arData.bDangerousGoods || '', 
            trackingNumber: arData.szTrackingNumber || '',
            trackingType: arData.szTrackingType || '', 
            liveScheduleDepartureTime: arData.iLiveScheduleDepartureTime || '', 
            liveScheduleArriavalTime: arData.iLiveScheduleArriavalTime || '',  
            liveScheduleLength: arData.iLiveScheduleLength || '', 
        };  
        this.addGoogleTags(arPageTracing);  
    } 

    public addGoogleTags(arData: any)
    {   
        if(arData.eventCategory)
        {
            let arDataLayer = arData;
            arDataLayer.event =  arData.eventCategory; 
            this._windowRef.nativeWindow.dataLayer.push(arDataLayer);   
        } 
    } 

    public fireTrackingPageLoadTime(arTrackingParams:any){ 
        let pageLoadStartTime:any = this._windowRef.nativeWindow.pageLoadStartTime;
        let pageLoadEndTime:any = (new Date().getTime());

        let iPageLoadTime:any = pageLoadEndTime - pageLoadStartTime;
        iPageLoadTime = Math.round(iPageLoadTime/1000);
        if(iPageLoadTime==0)
        {
            iPageLoadTime = 1;
        }
        let szAction = "";
        if(iPageLoadTime<=3)
        {
            szAction = "0s-3s";
        } else if(iPageLoadTime<=4){
            szAction = "3s-4s";
        } else if(iPageLoadTime<=5){
            szAction = "4s-5s";
        } else {
            szAction = "more than 5s";
        } 
        let arPageTrackingData = {
            "szCategory": "time",
            "szAction": szAction,
            "szLabel": iPageLoadTime + "s, " + arTrackingParams.szPageUrl
        };
        this.sendPageTracking(arPageTrackingData);
        this._windowRef.nativeWindow.iResetStatTime = 1; 
    }

    public sendGtagData(arData: any, szEventName: string)
    {
        this._GoogleAnalyticsService.sendGtagData(arData, szEventName);  
    }
}