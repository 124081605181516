import * as $ from "jquery";

export class CustomHelper {
    static loadStyles(tag, src) {
        if (Array.isArray(src)) {
            $.each(src, function (k, s) {
                $(tag).append($('<link/>').attr('href', s).attr('rel', 'stylesheet').attr('type', 'text/css'));
            });
        } else {
            $(tag).append($('<link/>').attr('href', src).attr('rel', 'stylesheet').attr('type', 'text/css'));
        }
    }

    static unwrapTag(element) {
        $(element).removeAttr('appunwraptag').unwrap();
    }

    /**
     * Set title markup
     * @param title
     */
    static setTitle(title: string) {
        $('.siteTitle').text(title);
    }

    static hideLoaderContent(enable: boolean) {
        if (enable) {
            $('.loader-hide-content').addClass('d-none');
            // $('.search-form-container').addClass('wow fadeInUp');
        } else {
            $('.loader-hide-content').removeClass('d-none');
            // $('.search-form-container').removeClass('wow fadeInUp');
        }
    }

    static manageHeaderBackGround(scrollOffSet: number, isLandingPage: boolean = true) {
        if (isLandingPage == true) {
            // if (scrollOffSet >= 100) {
            //     $('.swire-header').addClass('header-bg');
            // } else {
            //     $('.swire-header').removeClass('header-bg');
            // }
        } else {
            // if (scrollOffSet >= 100) {
            //     $('.swire-header').addClass('search-result-header-bg-color');
            //     $('.swire-header').removeClass('header-bg-img');
            // } else {

            //     $('.swire-header').addClass('header-bg-img');
            //     $('.swire-header').removeClass('search-result-header-bg-color');
            // }
        }

    }

    static showHideHeaderBackGroundImage(show: boolean, isStaticPage:boolean = false) {
        if (show) {   
            if( window.innerWidth <= 1024){     
                $('.logoImg').removeClass('d-none');
                $('.isMobileOrTab').addClass('d-none');
            }
            else {
                $('.isMobileOrTab').removeClass('d-none');
                $('.logoImg').addClass('d-none');
             }         
            if( window.innerWidth <= 767){     
                $('.isMobileOrTab').removeClass('d-none');
            }
            else {
                $('.isMobileOrTab').addClass('d-none');
             }
              $('.swire-header').addClass('header-bg-img');
              $('.swire-page-view').addClass('remove-viewport');
              $('.swire-page-view').removeClass('viewport-height');
              
              //for translation code start here
              setTimeout(() => {
              $('#translate-home-page').addClass('resultpage-dark-bg');  
              $('.logoImg').removeClass('d-none'); 
              if( window.innerWidth <= 1024){
                $('#translate-home-page-mob').addClass('resultpage-dark-bg'); 
              } 
              if( window.innerWidth <= 767){
                $('#translate-home-page-mob').addClass('resultpage-dark-bg'); 
              }   
            },300);                 
       
              //for translation code end here
        } else {  
              if( window.innerWidth <= 1024){
                  $('.logoImg').addClass('d-none');
                  $('.isMobileOrTab').removeClass('d-none');
            } 
               else{
                  $('.logoImg').removeClass('d-none');
                  if( window.innerWidth <= 767){     
                    $('.isMobileOrTab').removeClass('d-none');
                }
                else {
                    $('.isMobileOrTab').addClass('d-none');
                }
            }

            $('.swire-header').removeClass('header-bg-img');
            $('#translate-home-page').removeClass('resultpage-dark-bg');
            if( window.innerWidth <= 1024){
                $('#translate-home-page-mob').removeClass('resultpage-dark-bg'); 
              } 
              if( window.innerWidth <= 767){
                $('#translate-home-page-mob').removeClass('resultpage-dark-bg'); 
              }
            $('.swire-page-view').removeClass('remove-viewport');
            if (isStaticPage == false) {
                $('.swire-page-view').addClass('viewport-height');
            } else {
                $('.swire-page-view').removeClass('viewport-height');
            }
            $('.swire-header').removeClass('search-result-header-bg-color');
        }
    }

    static removeViewPortHeight(show: boolean, isStaticPage:boolean = false) {
        if (show) {
            $('.swire-page-view').removeClass('viewport-height');
            $('.swire-page-view').removeClass('remove-viewport');
        } else {
            if(isStaticPage == false){
                $('.swire-page-view').addClass('viewport-height');
            }else{
                $('.swire-page-view').removeClass('viewport-height');
            }
            
            $('.swire-header').removeClass('search-result-header-bg-color');
        }
    }



    static setLoading(enable: boolean, customMessage: string = '', loaderImage: string = '') {
        if (enable) {
            $('#custom-loader-message').html('');
            if ($.trim(customMessage) != '') {
                $('#custom-loader-message').html(customMessage);
            } else {
                $('#custom-loader-message').html('Your enquiry is currently being loaded.');
            }
            if ($.trim(loaderImage) != '') {
                $('#loader-image').html('<img src="./assets/app/media/img/loader/' + loaderImage + '">');
                $("#loader-image").removeClass('d-none');
                $("#loader-image-animation").addClass('d-none');
            } else {
                $("#loader-image").addClass('d-none');
                $("#loader-image-animation").removeClass('d-none');
                //$('#loader-image').html('<img src="./assets/app/media/img/loader/process-shipping-info">');
            }
            $('#swire-full-page-loader').removeClass('d-none');
        } else {
            $('#swire-full-page-loader').addClass('d-none');
        }
    }


    static showCustomSuccessMessage(szMessage: string = '', szImageName: string = '') {
        this.setLoading(true, szMessage, szImageName);
        setTimeout(() => {
            this.closeSuccessMessage();
        }, 3000);
    }

    static closeSuccessMessage() {
        this.setLoading(false);
    }

    static bodyAddClass(strClass) {
        $('body').addClass(strClass);
    }

    static bodyRemoveClass(strClass) {
        $('body').removeClass(strClass);
    }

    static getRandomString(length: number = 20, chars: any = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ') {
        var szTimeStamp = (new Date).getTime();
        var str = 'SC' + szTimeStamp;
        for (var i = length; i > 0; --i) {
            str += chars[Math.floor(Math.random() * chars.length)];
        }
        let customerKey = str.toLocaleUpperCase();
        return customerKey;
    }

    static countStringWords(str: string) {
        return str.split(/\s+/).length; // it splits the text on space/tab/enter
        // return str.split(' ').length;
    }

    static deleteObjfromArr(arr: any, id: any) {
        if (arr == null) {
            return null;
        }
        var newArr = arr.filter(function (obj) {
            return obj.idProduct !== id;
        });
        return newArr
    }
    static countCharacter(str: string) {
        return str.length; // replace(/\s/g, "") it splits the text on every character
        // return str.split(' ').length;
    }

    static getString(str: string, length: number) {
        return str.substring(0, length); // it splits the text on space/tab/enter
        // and return string of lenth word
    }


    static wordCounterController(str: string) {

        var textarea = '';
        var charactersCount = 0;
        var wordsCount = 0;
        var paragraphsCount = 0;
        var sentencesCount = 0;
        var readingTimeCount = 0 + 's';

        charactersCount = str.length;

        var words = str.match(/\b[-?(\w+)?]+\b/gi);
        var paragraphs = str.replace(/\n$/gm, '').split(/\n/);
        var sentences = str.split(/[.|!|?]+/g);
        if (words) {
            wordsCount = words.length;
            paragraphsCount = paragraphs.length;
            sentencesCount = sentences.length - 1;

            var seconds = Math.floor(words.length * 60 / 275);

            if (seconds > 59) {
                var minutes = Math.floor(seconds / 60);

                seconds = seconds - minutes * 60;
                readingTimeCount = minutes + 'm ' + seconds + 's';
            } else {
                readingTimeCount = seconds + 's';
            }
        }

        return wordsCount;
    }
    static scrollTopWithPixel(divId) {
        $('html,body').animate({
            scrollTop: $("#" + divId).offset().top + (-40)
        }, 1500);
    }

    static hideNavBarOnRouteChange() {

        if ($("#navbarResponsive").hasClass('show')) {
            (<any>$(".navbar-toggler")).click();
        }
    }

    static getFileTypeFromExtn(extn: string) {
        var fileType = 'file';
        switch (extn) {
            case '.jpg':
                fileType = 'file-image-o'
                break;
            case '.jpeg':
                fileType = 'file-image-o'
                break;
            case '.JFIF':
                fileType = 'file-image-o'
                break;
            case '.Exif':
                fileType = 'file-image-o'
                break;
            case '.TIFF':
                fileType = 'file-image-o'
                break;
            case '.GIF':
                fileType = 'file-image-o'
                break;
            case '.BMP':
                fileType = 'file-image-o'
                break;
            case '.pdf':
                fileType = 'file-pdf-o'
                break;
            case '.xls':
                fileType = 'file-excel-o'
                break;
            default:
                fileType = 'file'
                break;
        }

        return fileType;
    }

    static convertAryToStr(arData: any) {
        var strData = '';
        if (arData) {
            $.each(arData, function (index, value) {
                if ($.trim(strData) != '') {
                    strData = strData + "<br> " + value;
                } else {
                    strData = value;
                }
            });
        }

        return strData;
    }
    static camelize(str) {
        if (str) {
            let arStr = str.split(" ");
            let arTempStr = [];
            let iLength = arStr.length;
            let ctr = 1;
            for (let i in arStr) {
                let szStr = this.camelizeString(arStr[i]);
                arTempStr.push(szStr);
                if (iLength == ctr) {
                    return arTempStr.join(" ");
                }
                ctr++;
            }
        }
    }
    static spitStringByCharacter(str) {
        if (str) {
            let fullString = [];
            let res = str.split("<br/>");
            let iLength = res.length;
            let ctr = 1;
            for (let i in res) {
                let str = this.camelize(res[i]);
                fullString.push(str);
                if (iLength == ctr) {
                    return fullString.join(" ");
                }
                ctr++;
            }
        }
    }
    static camelizeString(str) {
        let bCapitalized = false;
        let counter = 0;
        let arSpecialCharacter = ['(', ')', '@', '$', '%'];
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            if (!bCapitalized) {
                bCapitalized = true;
                return word.toUpperCase();
            } else {
                return word.toLowerCase();
            }
        }).replace(/\s+/g, '');
    }
}