import * as $ from "jquery";
import { Injectable } from "@angular/core";

@Injectable()
export class ValidationService {

   static isCheckboxChecked(inputElementId:any)
   {
      if($("#"+inputElementId).attr('checked'))
      {
         return true;
      }
      else 
      {
         return false;
      }
   }

   static isFormValueEmpty(formFieldValue:any, formFieldId:any, iShowHideError:Number,isNumeric:boolean, showCommonError:boolean = false, customErrorMessage:string = '', showCustomErrorFieldId:string = '',errorStyleClass:string = '') 
   {	
      if(formFieldValue == null || formFieldValue == undefined || (String(formFieldValue).trim()) == '') 
      {
         if(iShowHideError==1)
         {
             this.showHideError(formFieldId,1,isNumeric, false, false,showCommonError, customErrorMessage, showCustomErrorFieldId,errorStyleClass);
         }
         return true;
      }
      else 
      {
         if(isNumeric == true){
            if(iShowHideError==1)
            {
               if (Number.isInteger(Number(formFieldValue)))
               {
                  this.showHideError(formFieldId,0,isNumeric, false, false,showCommonError, customErrorMessage, showCustomErrorFieldId,errorStyleClass);
               }else{
                  this.showHideError(formFieldId,1,isNumeric, false, false,showCommonError, customErrorMessage, showCustomErrorFieldId,errorStyleClass);
               }
               
            }
            return false;
            
         }else{
            if(iShowHideError==1)
            {
               if($("#"+formFieldId).attr('type') == 'email'){
                  if(this.isValidEmail(formFieldValue)){
                     this.showHideError(formFieldId,0,false,false,true,showCommonError, customErrorMessage, showCustomErrorFieldId,errorStyleClass);
                  }else{
                     this.showHideError(formFieldId,1,false,false,true,showCommonError, customErrorMessage, showCustomErrorFieldId,errorStyleClass);
                  }
               }else{
                  this.showHideError(formFieldId,0,isNumeric, false, false,showCommonError, customErrorMessage, showCustomErrorFieldId,errorStyleClass);
               }
                  
            }
            return false;
         }
      }
   }

   static isWordsInLimit(formFieldValue:any, formFieldId:any, iShowHideError:Number,totalEnterWordsCount:Number,maxAllowedWords:Number) 
   {	
      if(Number(totalEnterWordsCount) < 0) 
      {
         if(iShowHideError==1)
         {
            this.showHideError(formFieldId,1,false,true);
         }
         return true;
      }
      else 
      {
        
         if(iShowHideError==1)
         {
            this.showHideError(formFieldId,0,false,true);
         }
         return false;
      }
   }

   static showHideError(formFieldId:any, iToggle:Number,isNumeric:boolean=false,wordCountError:boolean = false, emailError:boolean = false, showCommonError:boolean = false, customErrorMessage:string = '', showCustomErrorFieldId:string = '', errorStyleClass:string = '')
   {
       if(showCommonError == true){
         
         if(iToggle==1)
         {
            if($("#"+formFieldId).length)
            {
               $("#"+formFieldId).addClass('error'); 
               if($("#"+formFieldId).next('ng-select').length){
                  //$("#"+formFieldId).next('ng-select').addClass("error");
               }
            }
            
            if($(".error-message-"+showCustomErrorFieldId).length)
            { 
               $(".error-message-"+showCustomErrorFieldId).removeClass('d-none');
               $("#"+showCustomErrorFieldId).addClass(errorStyleClass);
               $(".error-message-"+showCustomErrorFieldId).html(customErrorMessage);
            }
            
         }
         else
         {
            if($("#"+formFieldId).length)
            {
               $("#"+formFieldId).removeClass('error'); 
               if($("#"+formFieldId).next('ng-select').length){
                  //$("#"+formFieldId).next('ng-select').removeClass("error");
               }
            }
            if($(".error-message-"+showCustomErrorFieldId).length)
            { 
               $(".error-message-"+showCustomErrorFieldId).addClass('d-none');
               $("#"+showCustomErrorFieldId).removeClass(errorStyleClass);
               $(".error-message-"+showCustomErrorFieldId).html('');
            }
            
         }
      }else{
         if(iToggle==1)
         {
            if($("#"+formFieldId).length)
            {
               $("#"+formFieldId).addClass('error'); 
               if($("#"+formFieldId).next('ng-select').length){
                  //$("#"+formFieldId).next('ng-select').addClass("error");
               }
            }
            
            if(isNumeric == true){
         
               if($(".error-message-numeric-"+formFieldId).length)
               { 
                  if($(".error-message-"+formFieldId).length){
                     $(".error-message-"+formFieldId).addClass('d-none');
                  }
                  if($(".error-message-words-"+formFieldId).length){
                     $(".error-message-words-"+formFieldId).addClass('d-none');
                  }
                     
                  $(".error-message-numeric-"+formFieldId).removeClass('d-none');
               }
            }
            else if(wordCountError == true){
               if($(".error-message-"+formFieldId).length){
                  $(".error-message-"+formFieldId).addClass('d-none');
               }
               if($(".error-message-numeric-"+formFieldId).length){
                  $(".error-message-numeric-"+formFieldId).addClass('d-none');
               }
               $(".error-message-words-"+formFieldId).removeClass('d-none');
            }
            else if(emailError == true){
               if($(".error-message-"+formFieldId).length){
                  $(".error-message-"+formFieldId).addClass('d-none');
               }
               if($(".error-message-email-"+formFieldId).length){
                  $(".error-message-email-"+formFieldId).removeClass('d-none');
               }
            }
            else if(emailError == false){
               $(".error-message-email-"+formFieldId).addClass('d-none');
               $(".error-message-"+formFieldId).removeClass('d-none');
            }
            else{
              if($(".error-message-"+formFieldId).length)
               { 
                  if($(".error-message-words-"+formFieldId).length){
                     $(".error-message-words-"+formFieldId).addClass('d-none');
                  }
                  if($(".error-message-numeric-"+formFieldId).length){
                     $(".error-message-numeric-"+formFieldId).addClass('d-none');
                  }
                  $(".error-message-"+formFieldId).removeClass('d-none');
               }
            }
            
         }
         else
         {
            if($("#"+formFieldId).length)
            {
               $("#"+formFieldId).removeClass('error'); 
               if($("#"+formFieldId).next('ng-select').length){
                  $("#"+formFieldId).next('ng-select').removeClass("error");
               }
            }
            if(isNumeric == true){
               if($(".error-message-numeric-"+formFieldId).length)
               { 
                  $(".error-message-numeric-"+formFieldId).addClass('d-none');
               }
            }
            if(wordCountError == true){
               if($(".error-message-words-"+formFieldId).length)
               { 
                  $(".error-message-words-"+formFieldId).addClass('d-none');
               }
            }
            if(emailError == true){
               if($(".error-message-email-"+formFieldId).length)
               { 
                  $(".error-message-email-"+formFieldId).addClass('d-none');
               }
            }
            if($(".error-message-"+formFieldId).length)
            { 
               $(".error-message-"+formFieldId).addClass('d-none');
            }
         }
      }
     
   }

   static isFormInputElementEmpty(inputElementId:any, default_text:any) 
   {	
      let field_value=$("#"+inputElementId).attr('value');
      if((jQuery.trim(field_value) == "") || (field_value==default_text))
      {
         return true;
      }
      else 
      {
         return false;
      }
   }
   
   static isValidEmail(szEmail:string)
   {
      if(szEmail=='' || szEmail=='undefined')
      {
         return false;
      }
      else
      {
         szEmail = jQuery.trim(szEmail);
         var filter=/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
         if (filter.test(szEmail))
         {
               return true;
         }
         else
         {
               return false;
         }
      }
   }  
   static isMinMaxError( formFieldValue:any, formFieldId:any,szFieldIds:any){
     let errorInputId=  formFieldId.split('-');
     let newInputId;
     let validateTempature:boolean=false
         if(szFieldIds == 'fMinTemprature-customs'){
            validateTempature=true;
         }else if(szFieldIds == 'fMaxTemprature-customs'){
            validateTempature=true;
         }
         for(let i in errorInputId){
               newInputId=errorInputId[0] + '-' + errorInputId[2];
         }
         if((formFieldValue===0  || formFieldValue<0 )&& validateTempature==false ){
               $(".error-message-"+formFieldId).removeClass('d-none');
               $("#"+newInputId).addClass('error'); 
            }else if(validateTempature==true && ( formFieldValue < -99)){
               $("#"+newInputId).addClass('error'); 
               $(".error-message-"+formFieldId).removeClass('d-none');
           }else{
               $(".error-message-"+formFieldId).addClass('d-none');
            }
 
      if(szFieldIds == 'iTotalNumberOfUnit-customs' && formFieldValue > 999){
          $("#"+newInputId).addClass('error'); 
          $(".error-message-"+formFieldId).removeClass('d-none');
      }else if(szFieldIds == 'fLength-customs'  && formFieldValue > 99){
         $("#"+newInputId).addClass('error'); 
         $(".error-message-"+formFieldId).removeClass('d-none');
      }else if(szFieldIds == 'fWidth-customs'  && formFieldValue > 99){
         $("#"+newInputId).addClass('error'); 
         $(".error-message-"+formFieldId).removeClass('d-none');
      }else if(szFieldIds == 'fHeight-customs'  && formFieldValue > 99){
         $("#"+newInputId).addClass('error'); 
         $(".error-message-"+formFieldId).removeClass('d-none');
      }else if(szFieldIds == 'fHeight-customs'  && formFieldValue > 99){
         $("#"+newInputId).addClass('error'); 
         $(".error-message-"+formFieldId).removeClass('d-none');
      }else if(szFieldIds == 'fAverageWeight-customs'  && formFieldValue > 99999){
         $("#"+newInputId).addClass('error'); 
         $(".error-message-"+formFieldId).removeClass('d-none');
      }else if(szFieldIds == 'iStackablePackagesCount-customs' && formFieldValue > 999){
         $("#"+newInputId).addClass('error'); 
         $(".error-message-"+formFieldId).removeClass('d-none');
      }
 
}
 
}