import * as $ from "jquery";
import { ValidationService } from 'src/app/helpers/validateService';
import { Injectable } from "@angular/core";


@Injectable()
export class ValidateHelper {

   static validate_ng_select(id:string, formFieldValue:any,isNumeric:boolean=false, showCommonError:boolean = false, customErrorMessage:string = '', showCustomErrorFieldId:string = '',errorStyleClass:string = ''){ 
      ValidationService.isFormValueEmpty(formFieldValue, id, 1,isNumeric, showCommonError, customErrorMessage, showCustomErrorFieldId,errorStyleClass); 
   }

   static validate_textareaWordCount(id:string, formFieldValue:any,totalEnterWordsCount:number,maxAllowedWords:number){ 
      ValidationService.isWordsInLimit(formFieldValue, id, 1,totalEnterWordsCount,maxAllowedWords); 
   } 
   static validate_minMaxInupt(id:string, formFieldValue:any,szFieldIds:any ){ 
        ValidationService.isMinMaxError( formFieldValue, id,szFieldIds); 
   } 
   static removeErrorMsgOnReset(){
      $('.error-default').addClass("d-none");
      $('.form-control').removeClass("error");
      $('.center-selection').removeClass("error");
   }

   static validateInputChar(event: any,szFieldID:string){
      let  pattern ;
      if(szFieldID === 'fMinTemprature' || szFieldID === 'fMaxTemprature' ){
          pattern = /[0-9-]/;
      }else{
          pattern = /[0-9]/;
      }
      const inputChar = String.fromCharCode(event.charCode);
     if (!pattern.test(inputChar)) {    
         event.preventDefault();
      }
   }

    
}